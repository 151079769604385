
import { defineComponent } from "vue";
import { getQueryString } from "@/utils/url";
import { formatPrice, getFloat, kmbNumber, formatPercentageValue } from "@/utils/formatNumber";
import { displayDateFormat } from "@/utils/formatTime";
import { getLongStringShow } from "@/utils/formatString";
import { modifyImgSize } from "@/utils/imgSize";
import { compressToBase64 } from "lz-string";
export default defineComponent({
  name: "NftTransactions",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const getHref = () => {
      return `/item-detail?data=${compressToBase64(props.item.contract + '@' + 
        (props.item?.relatedItem?.tokenId || props.item?.tokenId || ""))
      }?${getQueryString()}`;
    };

    return {
      getQueryString,
      formatPrice,
      getFloat,
      displayDateFormat,
      kmbNumber,
      formatPercentageValue,
      getLongStringShow,
      modifyImgSize,
      getHref,
      compressToBase64
    };
  },
});
