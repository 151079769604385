import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex justify-between w-full" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "lowercase" }
const _hoisted_4 = { class: "text-neutral-44 text-sm mt-2" }
const _hoisted_5 = { class: "text-white mt-1 font-bold text-16" }
const _hoisted_6 = { class: "text-neutral-44 mt-2 text-xs" }
const _hoisted_7 = { class: "flex-shrink-0 flex-grow-0 w-20 relative" }
const _hoisted_8 = {
  key: 0,
  class: "w-20 h-20 absolute rounded-lg z-10 bg-primary-50"
}
const _hoisted_9 = {
  class: "w-20 h-20 object-cover relative rounded-lg overflow-hidden",
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazyload = _resolveDirective("lazyload")!

  return (_openBlock(), _createBlock("a", {
    class: "\n      w-full\n      bg-neutral-16\n      rounded-lg\n      p-4\n      mt-2\n      overflow-hidden\n      text-neutral-08\n      flex\n      text-xs\n      box-border\n    ",
    href: `/item-detail?data=${_ctx.compressToBase64(
      _ctx.item?.contract + '@' + _ctx.getTokens()
    )}`,
    target: "_self"
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", {
          class: ["\n            rounded-sm\n            px-2\n            py-px\n            text-xs\n            font-bold\n            inline-block\n            capitalize\n          ", {
            'text-yellow-100 bg-yellow-20': _ctx.item?.transferType === 'mint',
            'text-red-100 bg-red-20': _ctx.item?.transferType === 'sold',
            'text-green-100 bg-green-20': _ctx.item?.transferType === 'bought',
          }]
        }, [
          _createTextVNode(_toDisplayString(_ctx.item?.transferType), 1),
          _createVNode("span", _hoisted_3, _toDisplayString(Number(_ctx.item?.transferCount) > 1 ? " x " + _ctx.item?.transferCount : ""), 1)
        ], 2),
        _createVNode("div", _hoisted_4, _toDisplayString(_ctx.item?.collectionName), 1),
        _createVNode("div", _hoisted_5, _toDisplayString(_ctx.getTransactionTypeDisplay()), 1),
        _createVNode("div", _hoisted_6, _toDisplayString(_ctx.formatTime(_ctx.item?.transferTime * 1000).time +
            _ctx.formatTime(_ctx.item?.transferTime * 1000).type +
            " " +
            _ctx.$t("ago")), 1)
      ]),
      _createVNode("div", _hoisted_7, [
        (_ctx.item?.transferCount > 2)
          ? (_openBlock(), _createBlock("div", _hoisted_8))
          : _createCommentVNode("", true),
        (_ctx.item?.transferCount > 1)
          ? (_openBlock(), _createBlock("div", {
              key: 1,
              class: ["w-20 h-20 absolute rounded-lg z-20 bg-primary-100 opacity-80", { 'top-1.5 right-1.5': _ctx.item?.transferCount > 2 }]
            }, null, 2))
          : _createCommentVNode("", true),
        _createVNode("div", {
          class: ["w-20 h-20 absolute rounded-lg z-30 bg-neutral-20", {
            'top-3 right-3': _ctx.item?.transferCount > 2,
            'top-1.5 right-1.5': _ctx.item?.transferCount == 2,
          }]
        }, [
          _withDirectives(_createVNode("img", _hoisted_9, null, 512), [
            [_directive_lazyload, _ctx.modifyImgSize(_ctx.item?.coverImg, '_160x160')]
          ])
        ], 2)
      ])
    ])
  ], 8, ["href"]))
}