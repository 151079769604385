
import { defineComponent } from "vue";
import { getQueryString } from "@/utils/url";
import {
  kmbNumber,
  getFloat,
  formatPercentageValue,
} from "@/utils/formatNumber";
import { modifyImgSize } from "@/utils/imgSize";
import HeadImg from "@/components/commons/HeadImg.vue";

export default defineComponent({
  name: "BlueChip",
  components: {
    HeadImg,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    showLine: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      getQueryString,
      kmbNumber,
      modifyImgSize,
      getFloat,
      formatPercentageValue,
    };
  },
});
