
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "LoadingCard",

  props: {
    imgWidth: {
      type: String,
      default: "94px",
    },
  },
});
