
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";
import Nft from "@/components/commons/list/items/NftItem.vue";
import NftExplore from "@/components/commons/list/items/NftExplore.vue";
import NftCollections from "@/components/commons/list/items/NftCollections.vue";
import NftTransactions from "@/components/commons/list/items/NftTransactions.vue";
import NftSimple from "@/components/commons/list/items/NftSimple.vue";
import TrackingItem from "@/components/commons/list/items/TrackingItem.vue";
import RankItem from "@/components/commons/list/items/RankItem.vue";
import BlueChipItem from "@/components/commons/list/items/BlueChipItem.vue";
import HistoryItem from "@/components/commons/list/items/HistoryItem.vue";
import CollectionsItem from "@/components/commons/list/items/CollectionsItem.vue";
export default defineComponent({
  name: "ListItem",
  components: {
    Nft,
    NftExplore,
    NftCollections,
    NftTransactions,
    NftSimple,
    TrackingItem,
    RankItem,
    BlueChipItem,
    HistoryItem,
    CollectionsItem,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: "large", //large || small
    },
    rowIndex: {
      type: Number,
      default: 1,
    },
    request_id: {
      type: String,
      default: "",
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    imgWidth: {
      type: String,
      default: "94px",
    },
    imgNumber: {
      type: Number,
      default: 3,
    },
  },
  setup(props) {
    return {};
  },
});
