import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  ref: "itemDom",
  class: "itemDom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nft = _resolveComponent("Nft")!
  const _component_NftExplore = _resolveComponent("NftExplore")!
  const _component_NftCollections = _resolveComponent("NftCollections")!
  const _component_NftTransactions = _resolveComponent("NftTransactions")!
  const _component_NftSimple = _resolveComponent("NftSimple")!
  const _component_TrackingItem = _resolveComponent("TrackingItem")!
  const _component_RankItem = _resolveComponent("RankItem")!
  const _component_BlueChipItem = _resolveComponent("BlueChipItem")!
  const _component_HistoryItem = _resolveComponent("HistoryItem")!
  const _component_CollectionsItem = _resolveComponent("CollectionsItem")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.type === 'nfte')
      ? (_openBlock(), _createBlock(_component_Nft, {
          key: 0,
          size: "large",
          typeIndex: 0,
          item: _ctx.item?.nft_explore_obj,
          index: _ctx.index
        }, null, 8, ["item", "index"]))
      : (_ctx.type === 'nft')
        ? (_openBlock(), _createBlock(_component_Nft, {
            key: 1,
            size: "large",
            typeIndex: 1,
            item: _ctx.item?.nft_collection_obj,
            index: _ctx.index
          }, null, 8, ["item", "index"]))
        : (_ctx.type === 'nftm')
          ? (_openBlock(), _createBlock(_component_Nft, {
              key: 2,
              size: "small",
              typeIndex: 2,
              item: _ctx.item?.nft_sale_obj,
              index: _ctx.index
            }, null, 8, ["item", "index"]))
          : (_ctx.type === 'lexp')
            ? (_openBlock(), _createBlock(_component_NftExplore, {
                key: 3,
                showLine: _ctx.showLine,
                item: _ctx.item,
                index: _ctx.index
              }, null, 8, ["showLine", "item", "index"]))
            : (_ctx.type === 'lcol')
              ? (_openBlock(), _createBlock(_component_NftCollections, {
                  key: 4,
                  item: _ctx.item,
                  index: _ctx.index
                }, null, 8, ["item", "index"]))
              : (_ctx.type === 'ltra')
                ? (_openBlock(), _createBlock(_component_NftTransactions, {
                    key: 5,
                    item: _ctx.item,
                    index: _ctx.index
                  }, null, 8, ["item", "index"]))
                : (_ctx.type === 'lsim')
                  ? (_openBlock(), _createBlock(_component_NftSimple, {
                      key: 6,
                      item: _ctx.item,
                      index: _ctx.index
                    }, null, 8, ["item", "index"]))
                  : (_ctx.type === 'tracking')
                    ? (_openBlock(), _createBlock(_component_TrackingItem, {
                        key: 7,
                        item: _ctx.item,
                        index: _ctx.index,
                        showLine: _ctx.showLine
                      }, null, 8, ["item", "index", "showLine"]))
                    : (_ctx.type === 'rank')
                      ? (_openBlock(), _createBlock(_component_RankItem, {
                          key: 8,
                          item: _ctx.item,
                          index: _ctx.index
                        }, null, 8, ["item", "index"]))
                      : (_ctx.type === 'bluechip')
                        ? (_openBlock(), _createBlock(_component_BlueChipItem, {
                            key: 9,
                            item: _ctx.item,
                            index: _ctx.index
                          }, null, 8, ["item", "index"]))
                        : (_ctx.type === 'history')
                          ? (_openBlock(), _createBlock(_component_HistoryItem, {
                              key: 10,
                              item: _ctx.item,
                              index: _ctx.index
                            }, null, 8, ["item", "index"]))
                          : (_ctx.type === 'collections')
                            ? (_openBlock(), _createBlock(_component_CollectionsItem, {
                                key: 11,
                                item: _ctx.item,
                                index: _ctx.index,
                                imgWidth: _ctx.imgWidth,
                                imgNumber: _ctx.imgNumber,
                                onShowClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('showClick', _ctx.item, _ctx.index))),
                                onViewMore: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('viewMore', _ctx.item, _ctx.index)))
                              }, null, 8, ["item", "index", "imgWidth", "imgNumber"]))
                            : _createCommentVNode("", true)
  ], 512))
}