/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
/**
 * debounce
 * @param {fn} callback function
 * @param {number} wait times
 * @param {} immediate
 * @description Prevent events from firing multiple times
 */
 export const debounce = (func: any, wait = 500, immediate?: any) => {
  let timer;
  const im = immediate || false;
  return ( ...args ) => {
    if (timer) clearTimeout(timer);
    if (im) {
      const callNow = !timer;
      timer = setTimeout(function () {
        timer = null;
      }, wait)
      if (callNow) func(...args);
    }
    else {
      timer = setTimeout(function () {
        func(...args);
      }, wait);
    }
  }
}

