
import { defineComponent } from "vue";
import { getQueryString } from "@/utils/url";
import { kmbNumber, formatSmallNumber } from "@/utils/formatNumber";
import { modifyImgSize } from "@/utils/imgSize";
import { formatTime } from "@/utils/formatTime";
import { useI18n } from "vue-i18n";
import { compressToBase64 } from "lz-string";

export default defineComponent({
  name: "History",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    showLine: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const getTokens = () => {
      let tokens = '';
      const arr = (props?.item?.tokens||'').split(',');
      arr.forEach((item,index) => {
        const ct = item.split('-');
        if (index === 0) {
          tokens += ct?.[1] || '';
        } else {
          tokens += '-' + (ct?.[1] || '');
        }
      });
      return tokens;
    };
    const getTransactionTypeDisplay = () => {
      if (
        props.item?.transferType === "mint" &&
        Number(props.item?.price) === 0
      ) {
        return t("Free mint");
      } else {
        return (
          (props.item?.transferType === "sold" ? t("Receive") : t("Cost")) +
          " " +
          formatSmallNumber(props.item?.price) +
          " ETH"
        );
      }
    };
    return {
      getQueryString,
      kmbNumber,
      modifyImgSize,
      formatTime,
      getTransactionTypeDisplay,
      compressToBase64,
      getTokens,
    };
  },
});
