
import { defineComponent } from "vue";
import { getQueryString } from "@/utils/url";
import HeadImg from "@/components/commons/HeadImg.vue";
import { kmbNumber } from "@/utils/formatNumber";

export default defineComponent({
  name: "NftCollections",
  components: {
    HeadImg,
  },
  props: {
    size: {
      type: String,
      default: "large", //large || small
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return {
      getQueryString,
      kmbNumber,
    };
  },
});
