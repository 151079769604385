
import { defineComponent, reactive, computed, ref } from "vue";
import { debounce } from "@/utils/debounce";
import { getQueryString } from "@/utils/url";
import {
  formatPrice,
  getFloat,
  formatPercentageValue,
} from "@/utils/formatNumber";
import { useI18n } from "vue-i18n";
import { compressToBase64 } from "lz-string";

export default defineComponent({
  name: "Nft",
  props: {
    size: {
      type: String,
      default: "large", //large || small
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    typeIndex: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const loading = ref();
    const NFTData = (item, type) => {
      const {
        latestTx: {
          metadata: { image, name: name1, link },
          txHash,
        },
        name: name2,
        logo,
      }: NFTDataItem = item;

      let data;

      switch (type) {
        case "src":
          data = (image || "").replace("_288x288", "") ? image : logo;
          break;
        case "name":
          data = name1 || name2;
          break;
        case "href":
          data = link;
          break;
        case "hash":
          data = txHash;
          break;
        default:
          break;
      }

      return data;
    };

    const getHref = () => {
      return props.size === "large"
        ? `/collection-detail/${props.item?.contract?.address}?${getQueryString(
            ["typeIndex", "contract"]
          )}&typeIndex=${props.typeIndex}`
        : `/item-detail?data=${compressToBase64(props.item?.latestTx?.contract + '@' + props.item?.latestTx?.tokenId)}&${getQueryString()}`;
       
    };

    return {
      debounce,
      loading,
      getQueryString,
      formatPrice,
      NFTData,
      t,
      getHref,
      getFloat,
      formatPercentageValue,
      compressToBase64,
    };
  },
});

interface NFTDataItem {
  contract: {
    address: string;
    chainNet: string;
  };
  latestTx: {
    amount: string;
    blockNum: string;
    chainNet: string;
    contract: string;
    createTime: string;
    logAddress: string;
    metadata: {
      description: string;
      image: string;
      link: string;
      name: string;
      rawMsg: string;
      tokenId: string;
    };
    nftFrom: string;
    nftTo: string;
    operator: string;
    receipt: string;
    tokenId: string;
    tokenType: string;
    transferTime: string;
    txHash: string;
  };
  link: string;
  logo: string;
  name: string;
}
