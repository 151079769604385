
import { defineComponent, ref } from "vue";
import { getQueryString } from "@/utils/url";
import { kmbNumber, getFloat } from "@/utils/formatNumber";
import { modifyImgSize } from "@/utils/imgSize";
import HeadImg from "@/components/commons/HeadImg.vue";
import LoadingCard from "@/components/commons/LoadingCard.vue";
import { compressToBase64 } from "lz-string";
export default defineComponent({
  name: "Collections",
  components: {
    HeadImg,
    LoadingCard,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    imgWidth: {
      type: String,
      default: "94px",
    },
    imgNumber: {
      type: Number,
      default: 3,
    },
  },
  setup(props, { emit }) {
    const showNft = ref(false);
    const handleShow = () => {
      showNft.value = !showNft.value;
      emit("showClick");
    };
    const getNumber = () => {
      return props.item?.holdCount - props.item?.items.length >=
        props.imgNumber * 2
        ? props.imgNumber * 2
        : props.item?.holdCount - props.item?.items.length;
    };
    return {
      getQueryString,
      kmbNumber,
      getFloat,
      modifyImgSize,
      showNft,
      handleShow,
      getNumber,
      compressToBase64,
    };
  },
});
