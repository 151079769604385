
import { defineComponent } from "vue";
import { getQueryString } from "@/utils/url";
import { kmbNumber, getFloat } from "@/utils/formatNumber";
import { getLongStringShow } from "@/utils/formatString";
import { modifyImgSize } from "@/utils/imgSize";
import { compressToBase64 } from "lz-string";
export default defineComponent({
  name: "NftSimple",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return {
      getQueryString,
      kmbNumber,
      getFloat,
      modifyImgSize,
      getLongStringShow,
      compressToBase64,
    };
  },
});
